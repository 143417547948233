import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link as DoczLink } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { CopyableText } from '@entur/alert';
import { Paragraph, PreformattedText, Link } from '@entur/typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<CopyableText>#181c56</CopyableText>\n<CopyableText\n  successHeading=\"Kommando kopiert!\"\n  successMessage=\"Lim den inn i terminalen.\"\n>\n  npm install @entur/alert\n</CopyableText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      DoczLink,
      Playground,
      PageHeader,
      ImportStatement,
      CopyableText,
      Paragraph,
      PreformattedText,
      Link,
      DefaultLayout,
      _frontmatter
    }} style={{
      padding: '1.5rem 20% 0'
    }} mdxType="Playground">
  <CopyableText mdxType="CopyableText">#181c56</CopyableText>
  <CopyableText successHeading="Kommando kopiert!" successMessage="Lim den inn i terminalen." mdxType="CopyableText">
    npm install @entur/alert
  </CopyableText>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "copyabletext"
    }}>{`CopyableText`}</h3>
    <ImportStatement imports="CopyableText" mdxType="ImportStatement" />
    <Props of={CopyableText} mdxType="Props" />
    <h2 {...{
      "id": "obs-hvordan-bruke-komponent-med-toast"
    }}>{`OBS: Hvordan bruke komponent med toast`}</h2>
    <p>{`Denne komponenten benytter toast-varsling og vil ikke fungere uten en
`}<inlineCode parentName="p">{`ToastProvider`}</inlineCode>{`-wrapper.`}</p>
    <Paragraph mdxType="Paragraph">
  Les mer om hvordan du bruker ToastProvider og toast generelt under{' '}
  <Link as={DoczLink} to="/komponenter/feedback/alert" mdxType="Link">
    Alert sin dokumentasjonside.
  </Link>
    </Paragraph>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Copyable text passer fint i situasjoner der brukeren
trenger tilgang på kommandoer eller tokens fra Entur i eget arbeid og
det er sannsynlig at bruker ellers ville kopiert innholdet til utklippstavlen manuelt.`}</p>
    <p>{`Dette vil sannsynligvis være mest nyttig i utviklerportalen o.l. der brukeren kan ha
nytte av ulike innstallasjonskommandoer – slik man også finner her på
dokumentasjonsiden for designsystemet. Visse tilfeller kan likevel tilfalle vanlige sluttbrukere,
eksempelvis hvis brukeren trenger å bruke en lengre tekstlig kode.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      